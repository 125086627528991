// tnx.js
import tnxel from '../../../../../../../truenewx/tnxweb/vue3/src/element-plus/tnxel';

window.tnx = tnxel;

export const tnx = window.tnx;
export const $ = tnx.libs.$;
export const Vue = tnx.libs.Vue;
export const util = tnx.util;
export const app = tnx.app;

export default tnx;
