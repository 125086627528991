<template>
    <div>
        <tnxel-button icon="Loading" disabled v-if="sender.sending">发送中</tnxel-button>
        <el-button @click="toSend" v-else-if="sender.remainingSeconds <= 0">发送{{ caption }}</el-button>
        <el-button disabled v-else>重新发送 ({{ sender.remainingSeconds }})</el-button>
        <captcha-verify ref="captchaVerify" @success="send" v-if="captcha"/>
    </div>
</template>

<script>
import VerifyCodeSender from './verify-code-sender';
import {$, tnx} from '@/tnx';

export default {
    name: 'VerifyCodeButton',
    components: {
        'tnxel-button': tnx.components.Button,
        'captcha-verify': tnx.components.CaptchaVerify,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        cellphone: String,
        validate: Function,
        content: Object,
        caption: {
            type: String,
            default: '验证码',
        },
    },
    data() {
        return {
            sender: new VerifyCodeSender(this.type, this.caption),
            captcha: false,
        };
    },
    methods: {
        getType() {
            return this.type;
        },
        toSend() {
            if (this.validate) {
                let vm = this;
                this.validate(function() {
                    vm.send();
                });
            } else if (this.cellphone) {
                this.send();
            } else {
                $('#cellphone').focus();
            }
        },
        showCaptcha() {
            this.captcha = true;
            let vm = this;
            this.$nextTick(function() {
                vm.$refs.captchaVerify.show();
            });
        },
        send(params) {
            this.captcha = false;
            let vm = this;
            let content = Object.assign({}, this.content, params);
            this.sender.send(this.cellphone, null, content).catch(function() {
                vm.showCaptcha();
            });
        },
    }
}
</script>
