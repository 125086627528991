<template>
    <div class="flex-center flex-column" :style="{height: height + 'px'}">
        <tnxel-icon value="Loading" class="text-secondary" :size="32"/>
        <div :id="containerId" :style="{height: height + 'px', position: 'absolute'}"></div>
    </div>
</template>

<script>
export default {
    name: 'WechatLogin',
    components: {
        'tnxel-icon': window.tnx.components.Icon,
    },
    props: {
        redirectUri: {
            type: String,
            required: true,
        },
        containerId: {
            type: String,
            default() {
                return 'wechatLoginContainer';
            }
        },
        height: {
            type: Number,
            default() {
                let userAgent = navigator.userAgent.toLowerCase();
                if (window.location.origin.contains('://' + window.tnx.app.wechat.domain)
                    || !userAgent.contains('chrome')) {
                    return 300;
                }
                return 400;
            }
        },
    },
    methods: {
        build(options) {
            let vm = this;
            this.$nextTick(function() {
                window.tnx.app.wechat.login(vm.containerId, vm.redirectUri, options);
            });
        }
    }
}
</script>
