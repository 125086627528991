import './css-before';
import tnx from './tnx';
import './css-after';
import wechat from './wechat';
import WechatLogin from '@/components/wechat-login/WechatLogin';
import VerifyCodeButton from '@/components/verify-code-button/VerifyCodeButton';

tnx.app.wechat = wechat;
tnx.app.components = {
    WechatLogin,
    VerifyCodeButton,
}

window.tnx = tnx;
