// wechat.js
// app.js已经导入了本模块，不能再导入app.js，否则会形成循环依赖，导致出错
import Wechat from '../../../../../../../truenewx/tnxweb/core/src/tnxcore-wechat';

const domain = 'www.effigo.cn';

const wechat = {
    domain: domain,
    web: new Wechat('wx7b58073740880863', domain),
    sa: new Wechat('wx524ca37c4e27fffe', domain),
    login(containerId, redirectUri, options) {
        redirectUri = this.prepareRedirectUri(redirectUri);
        options = options || {};
        let app = window.tnx.app;
        if (!options.cssHref && app.isProduction()) {
            options.cssHref = app.context + '/assets/css/wxLogin.css';
        }
        options.state = Object.assign({}, options.state, {
            type: 'WEB'
        });
        this.web.login(containerId, redirectUri, options);
    },
    prepareRedirectUri(redirectUri) {
        if (!redirectUri.startsWith('/') && !redirectUri.startsWith('http://') && !redirectUri.startsWith('https://')) {
            let app = window.tnx.app;
            if (app.isProduction()) {
                if (redirectUri.startsWith("redirect:")) {
                    redirectUri = redirectUri.replace(':', '/').replace('//', '/');
                }
                redirectUri = app.context + '/wechat/' + redirectUri;
            } else {
                if (redirectUri.startsWith("redirect:")) {
                    redirectUri = redirectUri.replace('redirect:', 'redirect-cli-dev/').replace('//', '/');
                } else {
                    redirectUri += '/cli-dev';
                }
                redirectUri = process.env.VUE_APP_API_BASE_URL + '/wechat/' + redirectUri;
            }
        }
        return redirectUri;
    },
    authorize(redirectUri, state, silent) {
        redirectUri = this.prepareRedirectUri(redirectUri);
        state = Object.assign({}, state, {
            type: 'SA'
        });
        this.sa.authorize(redirectUri, state, silent);
    }
};

export default wechat;
