/**
 * 短信验证码发送器
 */
const tnx = window.tnx;
const app = tnx.app;

const VerifyCodeSender = function VerifyCodeSender(type, caption) {
    this.type = type;
    this.caption = caption || '验证码';
    this.sending = false;
    this.remainingSeconds = 0;
};

VerifyCodeSender.prototype.send = function(cellphone, ownerId, content) {
    if (ownerId && typeof ownerId === 'object') {
        content = ownerId;
        ownerId = null;
    }
    this.sending = true;
    let _this = this;
    return new Promise((resolve, reject) => {
        app.rpc.post('/verify/code', {
            type: this.type,
            target: cellphone,
            ownerId: ownerId,
        }, function(result) {
            _this.sending = false;
            if (result) {
                if (result.remainingSeconds < 0) {
                    tnx.error("错误的验证类型。这是一个程序错误，向我们反馈有奖~")
                } else {
                    _this.remainingSeconds = result.remainingSeconds;
                    _this.countdownRemainingSeconds();
                    if (result.errorMessage) {
                        tnx.error(result.errorMessage);
                    } else {
                        tnx.toast(_this.caption + "已发送", 2000, resolve);
                    }
                }
            } else {
                reject();
            }
        }, {
            params: content,
        });
    });
};

VerifyCodeSender.prototype.countdownRemainingSeconds = function() {
    if (this.remainingSeconds > 0) {
        let _this = this;
        let intervalId = setInterval(function() {
            if (--_this.remainingSeconds <= 0) {
                clearInterval(intervalId);
            }
        }, 1000);
    }
};

export default VerifyCodeSender;
